/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://5pjqhrc5draxffregraadxv7bu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://l7nvtmet84.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:74c3482d-8a18-4869-b23c-b509a8b3a978",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4W0tEM9rz",
    "aws_user_pools_web_client_id": "2nd1n8t5d8n22f10b9061i94cp",
    "oauth": {
        "domain": "verisk-cia-mlservices-master.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://mlservices.verisk.com/auth/callback/signin/",
        "redirectSignOut": "https://mlservices.verisk.com/auth/callback/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "mlservices-artifacts185655-master",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
