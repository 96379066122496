import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private nav: NavController,
    private router: Router,
    private title: Title
  ) {
  }

  ngOnInit() {
    // this.title.setTitle('Redirecting MyCheckin authentication...')
    this.route.params.subscribe( async (p) => {
      switch(p.event) {
        case 'signin':
          await this.auth.initialize();
          return this.nav.navigateRoot('/');
        case 'signout':
          this.auth.user = null;
          return this.nav.navigateRoot('/auth');
      }
    });
  }
}
