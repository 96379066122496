import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import Amplify from "aws-amplify";
import aws_exports from "../aws-exports";
import { AuthService } from './auth/auth.service';
Amplify.configure(aws_exports);
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public configs = [
    { title: 'Sign In', url: '/auth', icon: 'sign-in', fullscreen: true },
  ];
  public appPages = [
    {
      title: 'Projects',
      url: '/projects'
    },
    {
      title: 'Experiements',
      url: '/mlflow'
    },
    {
      title: 'Deployments',
      url: '/models'
    },
    {
      title: 'Monitoring',
      url: '/monitoring'
    },
  ]
  config;
  constructor(
    private router: Router,
    public auth: AuthService,
  ) {
    this.router.events.subscribe( (ev) => {
      if (ev instanceof NavigationStart) {
        this.config = this.handleRouterEvent(ev.url) || {
          fullscreen: false,
          navigating: true,
        }
      } else if (ev instanceof NavigationEnd) {
        this.config.navigating = false;
      }
    });
  }

  handleRouterEvent(url) {
    for (let p of this.configs) {
      if (p.url == url) {
        return p;
      }
    }
    return;
  }

  handleProfileClick() {
    if (this.auth.user) {
      return this.auth.signOut();
    } else {
      return this.auth.oktaSignIn();
    }
  }
}
