/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreatePayloadInput = {
  payloadId: string;
  apiKeyId?: string | null;
  inputs: Array<PayloadInputInput>;
  models: Array<PayloadModelInput>;
  status?: PayloadStatus | null;
  elementStatus?: PayloadComputeStatusInput | null;
  total: number;
  received?: string | null;
  modified?: string | null;
  owner?: string | null;
};

export type PayloadInputInput = {
  inputId?: string | null;
  type?: string | null;
  source?: string | null;
  value?: string | null;
  params?: string | null;
  index?: number | null;
  metadata?: string | null;
  owner?: string | null;
  error?: string | null;
};

export type PayloadModelInput = {
  modelId?: string | null;
  type?: string | null;
  version?: string | null;
  params?: string | null;
  error?: string | null;
};

export enum PayloadStatus {
  RUNNING = "RUNNING",
  ERROR = "ERROR",
  SUCCESSFUL = "SUCCESSFUL",
  SUCCESSFUL_WITH_ERROR = "SUCCESSFUL_WITH_ERROR"
}

export type PayloadComputeStatusInput = {
  RUNNING?: number | null;
  SUCCESSFUL?: number | null;
  ERROR?: number | null;
};

export type ModelPayloadConditionInput = {
  apiKeyId?: ModelStringInput | null;
  status?: ModelPayloadStatusInput | null;
  total?: ModelIntInput | null;
  received?: ModelStringInput | null;
  modified?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelPayloadConditionInput | null> | null;
  or?: Array<ModelPayloadConditionInput | null> | null;
  not?: ModelPayloadConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelPayloadStatusInput = {
  eq?: PayloadStatus | null;
  ne?: PayloadStatus | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdatePayloadInput = {
  payloadId: string;
  apiKeyId?: string | null;
  inputs?: Array<PayloadInputInput> | null;
  models?: Array<PayloadModelInput> | null;
  status?: PayloadStatus | null;
  elementStatus?: PayloadComputeStatusInput | null;
  total?: number | null;
  received?: string | null;
  modified?: string | null;
  owner?: string | null;
};

export type DeletePayloadInput = {
  payloadId: string;
};

export type CreatePayloadElementInput = {
  elementId: string;
  payloadId?: string | null;
  inputId?: string | null;
  payloadInput?: PayloadInputInput | null;
  modelId?: string | null;
  payloadModel?: PayloadModelInput | null;
  status?: PayloadElementStatus | null;
  metadata?: string | null;
  result?: string | null;
  error?: string | null;
  feedback?: string | null;
  label?: string | null;
  started?: string | null;
  modified?: string | null;
};

export enum PayloadElementStatus {
  RUNNING = "RUNNING",
  SUCCESSFUL = "SUCCESSFUL",
  ERROR = "ERROR"
}

export type ModelPayloadElementConditionInput = {
  payloadId?: ModelStringInput | null;
  inputId?: ModelStringInput | null;
  modelId?: ModelStringInput | null;
  status?: ModelPayloadElementStatusInput | null;
  metadata?: ModelStringInput | null;
  result?: ModelStringInput | null;
  error?: ModelStringInput | null;
  feedback?: ModelStringInput | null;
  label?: ModelStringInput | null;
  started?: ModelStringInput | null;
  modified?: ModelStringInput | null;
  and?: Array<ModelPayloadElementConditionInput | null> | null;
  or?: Array<ModelPayloadElementConditionInput | null> | null;
  not?: ModelPayloadElementConditionInput | null;
};

export type ModelPayloadElementStatusInput = {
  eq?: PayloadElementStatus | null;
  ne?: PayloadElementStatus | null;
};

export type UpdatePayloadElementInput = {
  elementId: string;
  payloadId?: string | null;
  inputId?: string | null;
  payloadInput?: PayloadInputInput | null;
  modelId?: string | null;
  payloadModel?: PayloadModelInput | null;
  status?: PayloadElementStatus | null;
  metadata?: string | null;
  result?: string | null;
  error?: string | null;
  feedback?: string | null;
  label?: string | null;
  started?: string | null;
  modified?: string | null;
};

export type DeletePayloadElementInput = {
  elementId: string;
};

export type CreateModelInput = {
  modelId: string;
  modelVersion?: string | null;
  mlflowID?: string | null;
  hostUri?: string | null;
  modelType?: string | null;
  kongPath?: string | null;
  kongConfig?: string | null;
  mlflowConfig?: string | null;
  stepFunctionConfig?: string | null;
  fargateConfig?: string | null;
};

export type ModelModelConditionInput = {
  modelVersion?: ModelStringInput | null;
  mlflowID?: ModelStringInput | null;
  hostUri?: ModelStringInput | null;
  modelType?: ModelStringInput | null;
  kongPath?: ModelStringInput | null;
  kongConfig?: ModelStringInput | null;
  mlflowConfig?: ModelStringInput | null;
  stepFunctionConfig?: ModelStringInput | null;
  fargateConfig?: ModelStringInput | null;
  and?: Array<ModelModelConditionInput | null> | null;
  or?: Array<ModelModelConditionInput | null> | null;
  not?: ModelModelConditionInput | null;
};

export type UpdateModelInput = {
  modelId: string;
  modelVersion?: string | null;
  mlflowID?: string | null;
  hostUri?: string | null;
  modelType?: string | null;
  kongPath?: string | null;
  kongConfig?: string | null;
  mlflowConfig?: string | null;
  stepFunctionConfig?: string | null;
  fargateConfig?: string | null;
};

export type DeleteModelInput = {
  modelId: string;
};

export type ModelPayloadFilterInput = {
  payloadId?: ModelIDInput | null;
  apiKeyId?: ModelStringInput | null;
  status?: ModelPayloadStatusInput | null;
  total?: ModelIntInput | null;
  received?: ModelStringInput | null;
  modified?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  and?: Array<ModelPayloadFilterInput | null> | null;
  or?: Array<ModelPayloadFilterInput | null> | null;
  not?: ModelPayloadFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelPayloadElementFilterInput = {
  elementId?: ModelIDInput | null;
  payloadId?: ModelStringInput | null;
  inputId?: ModelStringInput | null;
  modelId?: ModelStringInput | null;
  status?: ModelPayloadElementStatusInput | null;
  metadata?: ModelStringInput | null;
  result?: ModelStringInput | null;
  error?: ModelStringInput | null;
  feedback?: ModelStringInput | null;
  label?: ModelStringInput | null;
  started?: ModelStringInput | null;
  modified?: ModelStringInput | null;
  and?: Array<ModelPayloadElementFilterInput | null> | null;
  or?: Array<ModelPayloadElementFilterInput | null> | null;
  not?: ModelPayloadElementFilterInput | null;
};

export type ModelModelFilterInput = {
  modelId?: ModelIDInput | null;
  modelVersion?: ModelStringInput | null;
  mlflowID?: ModelStringInput | null;
  hostUri?: ModelStringInput | null;
  modelType?: ModelStringInput | null;
  kongPath?: ModelStringInput | null;
  kongConfig?: ModelStringInput | null;
  mlflowConfig?: ModelStringInput | null;
  stepFunctionConfig?: ModelStringInput | null;
  fargateConfig?: ModelStringInput | null;
  and?: Array<ModelModelFilterInput | null> | null;
  or?: Array<ModelModelFilterInput | null> | null;
  not?: ModelModelFilterInput | null;
};

export type CreatePayloadMutation = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type UpdatePayloadMutation = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type DeletePayloadMutation = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type CreatePayloadElementMutation = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type UpdatePayloadElementMutation = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type DeletePayloadElementMutation = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type CreateModelMutation = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateModelMutation = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteModelMutation = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetPayloadQuery = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type ListPayloadsQuery = {
  __typename: "ModelPayloadConnection";
  items: Array<{
    __typename: "Payload";
    payloadId: string;
    apiKeyId: string | null;
    status: PayloadStatus | null;
    total: number;
    received: string | null;
    modified: string | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type PayloadByApiKeyIdByStatusQuery = {
  __typename: "ModelPayloadConnection";
  items: Array<{
    __typename: "Payload";
    payloadId: string;
    apiKeyId: string | null;
    status: PayloadStatus | null;
    total: number;
    received: string | null;
    modified: string | null;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetPayloadElementQuery = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type ListPayloadElementsQuery = {
  __typename: "ModelPayloadElementConnection";
  items: Array<{
    __typename: "PayloadElement";
    elementId: string;
    payloadId: string | null;
    inputId: string | null;
    modelId: string | null;
    status: PayloadElementStatus | null;
    metadata: string | null;
    result: string | null;
    error: string | null;
    feedback: string | null;
    label: string | null;
    started: string | null;
    modified: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type PayloadElementByPayloadByStatusQuery = {
  __typename: "ModelPayloadElementConnection";
  items: Array<{
    __typename: "PayloadElement";
    elementId: string;
    payloadId: string | null;
    inputId: string | null;
    modelId: string | null;
    status: PayloadElementStatus | null;
    metadata: string | null;
    result: string | null;
    error: string | null;
    feedback: string | null;
    label: string | null;
    started: string | null;
    modified: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetModelQuery = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListModelsQuery = {
  __typename: "ModelModelConnection";
  items: Array<{
    __typename: "Model";
    modelId: string;
    modelVersion: string | null;
    mlflowID: string | null;
    hostUri: string | null;
    modelType: string | null;
    kongPath: string | null;
    kongConfig: string | null;
    mlflowConfig: string | null;
    stepFunctionConfig: string | null;
    fargateConfig: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreatePayloadSubscription = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type OnUpdatePayloadSubscription = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type OnDeletePayloadSubscription = {
  __typename: "Payload";
  payloadId: string;
  apiKeyId: string | null;
  inputs: Array<{
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  }>;
  models: Array<{
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  }>;
  status: PayloadStatus | null;
  elementStatus: {
    __typename: "PayloadComputeStatus";
    RUNNING: number | null;
    SUCCESSFUL: number | null;
    ERROR: number | null;
  } | null;
  total: number;
  received: string | null;
  modified: string | null;
  owner: string | null;
};

export type OnCreatePayloadElementSubscription = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type OnUpdatePayloadElementSubscription = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type OnDeletePayloadElementSubscription = {
  __typename: "PayloadElement";
  elementId: string;
  payloadId: string | null;
  inputId: string | null;
  payloadInput: {
    __typename: "PayloadInput";
    inputId: string | null;
    type: string | null;
    source: string | null;
    value: string | null;
    params: string | null;
    index: number | null;
    metadata: string | null;
    owner: string | null;
    error: string | null;
  } | null;
  modelId: string | null;
  payloadModel: {
    __typename: "PayloadModel";
    modelId: string | null;
    type: string | null;
    version: string | null;
    params: string | null;
    error: string | null;
  } | null;
  status: PayloadElementStatus | null;
  metadata: string | null;
  result: string | null;
  error: string | null;
  feedback: string | null;
  label: string | null;
  started: string | null;
  modified: string | null;
};

export type OnCreateModelSubscription = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateModelSubscription = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteModelSubscription = {
  __typename: "Model";
  modelId: string;
  modelVersion: string | null;
  mlflowID: string | null;
  hostUri: string | null;
  modelType: string | null;
  kongPath: string | null;
  kongConfig: string | null;
  mlflowConfig: string | null;
  stepFunctionConfig: string | null;
  fargateConfig: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreatePayload(
    input: CreatePayloadInput,
    condition?: ModelPayloadConditionInput
  ): Promise<CreatePayloadMutation> {
    const statement = `mutation CreatePayload($input: CreatePayloadInput!, $condition: ModelPayloadConditionInput) {
        createPayload(input: $input, condition: $condition) {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePayloadMutation>response.data.createPayload;
  }
  async UpdatePayload(
    input: UpdatePayloadInput,
    condition?: ModelPayloadConditionInput
  ): Promise<UpdatePayloadMutation> {
    const statement = `mutation UpdatePayload($input: UpdatePayloadInput!, $condition: ModelPayloadConditionInput) {
        updatePayload(input: $input, condition: $condition) {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePayloadMutation>response.data.updatePayload;
  }
  async DeletePayload(
    input: DeletePayloadInput,
    condition?: ModelPayloadConditionInput
  ): Promise<DeletePayloadMutation> {
    const statement = `mutation DeletePayload($input: DeletePayloadInput!, $condition: ModelPayloadConditionInput) {
        deletePayload(input: $input, condition: $condition) {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePayloadMutation>response.data.deletePayload;
  }
  async CreatePayloadElement(
    input: CreatePayloadElementInput,
    condition?: ModelPayloadElementConditionInput
  ): Promise<CreatePayloadElementMutation> {
    const statement = `mutation CreatePayloadElement($input: CreatePayloadElementInput!, $condition: ModelPayloadElementConditionInput) {
        createPayloadElement(input: $input, condition: $condition) {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePayloadElementMutation>response.data.createPayloadElement;
  }
  async UpdatePayloadElement(
    input: UpdatePayloadElementInput,
    condition?: ModelPayloadElementConditionInput
  ): Promise<UpdatePayloadElementMutation> {
    const statement = `mutation UpdatePayloadElement($input: UpdatePayloadElementInput!, $condition: ModelPayloadElementConditionInput) {
        updatePayloadElement(input: $input, condition: $condition) {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePayloadElementMutation>response.data.updatePayloadElement;
  }
  async DeletePayloadElement(
    input: DeletePayloadElementInput,
    condition?: ModelPayloadElementConditionInput
  ): Promise<DeletePayloadElementMutation> {
    const statement = `mutation DeletePayloadElement($input: DeletePayloadElementInput!, $condition: ModelPayloadElementConditionInput) {
        deletePayloadElement(input: $input, condition: $condition) {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePayloadElementMutation>response.data.deletePayloadElement;
  }
  async CreateModel(
    input: CreateModelInput,
    condition?: ModelModelConditionInput
  ): Promise<CreateModelMutation> {
    const statement = `mutation CreateModel($input: CreateModelInput!, $condition: ModelModelConditionInput) {
        createModel(input: $input, condition: $condition) {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateModelMutation>response.data.createModel;
  }
  async UpdateModel(
    input: UpdateModelInput,
    condition?: ModelModelConditionInput
  ): Promise<UpdateModelMutation> {
    const statement = `mutation UpdateModel($input: UpdateModelInput!, $condition: ModelModelConditionInput) {
        updateModel(input: $input, condition: $condition) {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateModelMutation>response.data.updateModel;
  }
  async DeleteModel(
    input: DeleteModelInput,
    condition?: ModelModelConditionInput
  ): Promise<DeleteModelMutation> {
    const statement = `mutation DeleteModel($input: DeleteModelInput!, $condition: ModelModelConditionInput) {
        deleteModel(input: $input, condition: $condition) {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteModelMutation>response.data.deleteModel;
  }
  async GetPayload(payloadId: string): Promise<GetPayloadQuery> {
    const statement = `query GetPayload($payloadId: ID!) {
        getPayload(payloadId: $payloadId) {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      payloadId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPayloadQuery>response.data.getPayload;
  }
  async ListPayloads(
    payloadId?: string,
    filter?: ModelPayloadFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListPayloadsQuery> {
    const statement = `query ListPayloads($payloadId: ID, $filter: ModelPayloadFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listPayloads(payloadId: $payloadId, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            payloadId
            apiKeyId
            status
            total
            received
            modified
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (payloadId) {
      gqlAPIServiceArguments.payloadId = payloadId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPayloadsQuery>response.data.listPayloads;
  }
  async PayloadByApiKeyIdByStatus(
    apiKeyId?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelPayloadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PayloadByApiKeyIdByStatusQuery> {
    const statement = `query PayloadByApiKeyIdByStatus($apiKeyId: String, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelPayloadFilterInput, $limit: Int, $nextToken: String) {
        PayloadByApiKeyIdByStatus(apiKeyId: $apiKeyId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            payloadId
            apiKeyId
            status
            total
            received
            modified
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (apiKeyId) {
      gqlAPIServiceArguments.apiKeyId = apiKeyId;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PayloadByApiKeyIdByStatusQuery>(
      response.data.PayloadByApiKeyIdByStatus
    );
  }
  async GetPayloadElement(elementId: string): Promise<GetPayloadElementQuery> {
    const statement = `query GetPayloadElement($elementId: ID!) {
        getPayloadElement(elementId: $elementId) {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`;
    const gqlAPIServiceArguments: any = {
      elementId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPayloadElementQuery>response.data.getPayloadElement;
  }
  async ListPayloadElements(
    elementId?: string,
    filter?: ModelPayloadElementFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListPayloadElementsQuery> {
    const statement = `query ListPayloadElements($elementId: ID, $filter: ModelPayloadElementFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listPayloadElements(elementId: $elementId, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            elementId
            payloadId
            inputId
            modelId
            status
            metadata
            result
            error
            feedback
            label
            started
            modified
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (elementId) {
      gqlAPIServiceArguments.elementId = elementId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPayloadElementsQuery>response.data.listPayloadElements;
  }
  async PayloadElementByPayloadByStatus(
    payloadId?: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelPayloadElementFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PayloadElementByPayloadByStatusQuery> {
    const statement = `query PayloadElementByPayloadByStatus($payloadId: String, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelPayloadElementFilterInput, $limit: Int, $nextToken: String) {
        PayloadElementByPayloadByStatus(payloadId: $payloadId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            elementId
            payloadId
            inputId
            modelId
            status
            metadata
            result
            error
            feedback
            label
            started
            modified
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (payloadId) {
      gqlAPIServiceArguments.payloadId = payloadId;
    }
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PayloadElementByPayloadByStatusQuery>(
      response.data.PayloadElementByPayloadByStatus
    );
  }
  async GetModel(modelId: string): Promise<GetModelQuery> {
    const statement = `query GetModel($modelId: ID!) {
        getModel(modelId: $modelId) {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      modelId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetModelQuery>response.data.getModel;
  }
  async ListModels(
    modelId?: string,
    filter?: ModelModelFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListModelsQuery> {
    const statement = `query ListModels($modelId: ID, $filter: ModelModelFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listModels(modelId: $modelId, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
          __typename
          items {
            __typename
            modelId
            modelVersion
            mlflowID
            hostUri
            modelType
            kongPath
            kongConfig
            mlflowConfig
            stepFunctionConfig
            fargateConfig
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (modelId) {
      gqlAPIServiceArguments.modelId = modelId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListModelsQuery>response.data.listModels;
  }
  OnCreatePayloadListener: Observable<
    SubscriptionResponse<OnCreatePayloadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePayload {
        onCreatePayload {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreatePayloadSubscription>>;

  OnUpdatePayloadListener: Observable<
    SubscriptionResponse<OnUpdatePayloadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePayload {
        onUpdatePayload {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdatePayloadSubscription>>;

  OnDeletePayloadListener: Observable<
    SubscriptionResponse<OnDeletePayloadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePayload {
        onDeletePayload {
          __typename
          payloadId
          apiKeyId
          inputs {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          models {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          elementStatus {
            __typename
            RUNNING
            SUCCESSFUL
            ERROR
          }
          total
          received
          modified
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeletePayloadSubscription>>;

  OnCreatePayloadElementListener: Observable<
    SubscriptionResponse<OnCreatePayloadElementSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePayloadElement {
        onCreatePayloadElement {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreatePayloadElementSubscription>>;

  OnUpdatePayloadElementListener: Observable<
    SubscriptionResponse<OnUpdatePayloadElementSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePayloadElement {
        onUpdatePayloadElement {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdatePayloadElementSubscription>>;

  OnDeletePayloadElementListener: Observable<
    SubscriptionResponse<OnDeletePayloadElementSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePayloadElement {
        onDeletePayloadElement {
          __typename
          elementId
          payloadId
          inputId
          payloadInput {
            __typename
            inputId
            type
            source
            value
            params
            index
            metadata
            owner
            error
          }
          modelId
          payloadModel {
            __typename
            modelId
            type
            version
            params
            error
          }
          status
          metadata
          result
          error
          feedback
          label
          started
          modified
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeletePayloadElementSubscription>>;

  OnCreateModelListener: Observable<
    SubscriptionResponse<OnCreateModelSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateModel {
        onCreateModel {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateModelSubscription>>;

  OnUpdateModelListener: Observable<
    SubscriptionResponse<OnUpdateModelSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateModel {
        onUpdateModel {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateModelSubscription>>;

  OnDeleteModelListener: Observable<
    SubscriptionResponse<OnDeleteModelSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteModel {
        onDeleteModel {
          __typename
          modelId
          modelVersion
          mlflowID
          hostUri
          modelType
          kongPath
          kongConfig
          mlflowConfig
          stepFunctionConfig
          fargateConfig
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteModelSubscription>>;
}
