import { Injectable } from "@angular/core";
import { Auth } from "@aws-amplify/auth";
import { APIService } from "../API.service";
import { NavController } from '@ionic/angular';


@Injectable()
export class AuthService {
  public reload = true;
  // private metaSub;
  user;
  
  public get authenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }
  public get userInfo() {
    return Auth.currentUserInfo();
  }
  public get userGroups() {
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          return user.signInUserSession.accessToken.payload["cognito:groups"];
        }
        return;
      })
  }

  public get isAdmin() {
    return this.userGroups
      .then((grp) => {
        try {
          return grp.includes("admins");
        } catch {
          return false;
        }
      });
  }

  constructor(
    private api: APIService,
    private nav: NavController,
    ) {
  }

  public async registerUser(data) {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch {
      user = null;
    }
    if (user) {
      // if (!this.user) {
      //   this.user = await this.api
      //     .CreateUser({
      //       id: user.username,
      //       name: user.signInUserSession.idToken.payload.name,
      //       email: user.signInUserSession.idToken.payload.email,
      //       ...data
      //     });
      //   this.initialize(user);
      // }
    }
  }

  public async initialize(user?) {
    try {
      this.user = await Auth.currentAuthenticatedUser();
      return this.user;
    } catch {
      return false;
    }
  }

  async oktaSignIn() {
    return await Auth.federatedSignIn({
      customProvider: 'okta',
    });
  }

  clearUser() {
    // this.user = null;
    // if (this.metaSub) {
    //   clearInterval(this.metaSub);
    // }
  }

  /** signout */
  public signOut() {
    return Auth.signOut().then( (signedout) => {
      this.clearUser();
      this.nav.navigateRoot('/auth');
    })
  }
}
